/* src/fonts.css */

@font-face {
  font-family: "Switzer";
  src: url("./fonts/Switzer-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("./fonts/Switzer-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("./fonts/Switzer-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("./fonts/Switzer-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geist";
  src: url("./fonts/Geist-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geist";
  src: url("./fonts/Geist-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geist";
  src: url("./fonts/Geist-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Geist";
  src: url("./fonts/Geist-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
