.contact-us-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  min-height: 100vh;
  color: #333;
}

.contact-title {
  font-family: serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
}

.contact-last-updated {
  font-family: sans-serif;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 2rem;
}

.contact-content {
  font-family: sans-serif;
  line-height: 1.6;
}

.contact-info {
  margin-top: 1.5rem;
}

.contact-info p {
  margin-bottom: 0.5rem;
} 