.line {
  height: 1px;
  align-self: stretch;
  opacity: 0.1;
  background: var(--text-color);
}

.PhoneInputInput {
  background-color: var(--background-color);
  color: var(--text-color);
}

.host-info {
  display: flex;
  padding-left: 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin: 12px 0px;
}

.host-info-text {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.host-info-image {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
}

.trip-details {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.trip-details-item {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px dashed var(--text-color);
  background: var(--room-card-bg);
  align-self: stretch;
}

.trip-details-item-title {
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.5;
}

.trip-details-item-text {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.trip-details-item-title-text {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.request-section-title {
  color: var(--text-color);
  font-family: Geist;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
}

.guest-index {
  color: var(--text-color);
  font-family: Geist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.4px;
}

.request-section-subtitle {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  opacity: 0.5;
}

.number-of-guest-container {
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: 24px 0px;
}

.number-of-guest-title {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
}

.number-of-guest-input-container {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid var(--checkout-card-border);
}

.number-of-guest-input {
  color: var(--text-color);
  font-family: Geist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.4px;
}

.guest-details-input {
  display: flex;
  padding: 16px 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--checkout-card-border);
  color: var(--text-color);
  background: var(--background-color);
  width: -webkit-fill-available;
  height: 14px;
  outline: none;
}

.guest-details-input-text {
  color: var(--text-color);
  font-family: Geist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.24px;
  outline: none;
  opacity: 0.5;
}

.guest-details-input-label {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.32px;
  margin-top: 32px;
}

.guest-details-input-label-optional {
  color: var(--text-color);
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.32px;
  opacity: 0.5;
}

.submit-button-active {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: #15cd72;
  box-shadow: 0px 3px 0px 0px #18af6d,
    0px 2px 2px 0px rgba(255, 255, 255, 0.05) inset;
  color: #fff;
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.64px;
  width: -webkit-fill-available;
  cursor: pointer;
}

.submit-button-inactive {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: grey;
  box-shadow: 0px 3px 0px 0px lightgrey,
    0px 2px 2px 0px rgba(255, 255, 255, 0.05) inset;
  color: #fff;
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.64px;
  width: -webkit-fill-available;
  cursor: not-allowed;
}

.hurry-text {
  color: var(--text-color);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: -0.36px;
  opacity: 0.8;
  text-align: center;
}

.trip-details-title {
  color: var(--text-color);
  font-family: Geist;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.4px;
  opacity: 0.95;
}

.host-and-trip-details-container {
  display: flex;
  width: 370px;
  padding: 26px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  border: 1px dashed var(--checkout-card-border);
  background: var(--background-color);
  box-shadow: 0px 4px 16px 0px var(--checkout-card-border);
}

.host-and-trip-details-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.dashed-line {
  border-bottom: 1px dashed var(--text-color);
  height: 1px;
  align-self: stretch;
  opacity: 0.2;
}

.hurry-up-div {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff0da;
}

.hurry-up-div-text {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.42px;
  opacity: 0.8;
}

.sticky-div-checkout {
  position: sticky;
  top: 24px;
  height: fit-content;
}

@media (min-width: 768px) {
  .trip-details-title {
    color: var(--text-color);
    font-family: Geist;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.64px;
    opacity: 0.95;
  }
}

.confirmation-header {
  color: var(--text-color);
  text-align: center;
  font-family: Geist;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -1.28px;
  opacity: 0.95;
  margin-top: 29px;
  margin-bottom: 12px;
}
.confirmation-subheader {
  color: var(--text-color);
  text-align: center;
  font-family: Geist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
}

.MuiDrawer-paper {
  border-radius: 24px 24px 0px 0px;
}
