.heading {
  font-family: Poppins;
  color: black;
  line-height: 150%;
  font-weight: 500;
}

.text_checkout {
  font-family: Poppins;
  color: black;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}

.savedText {
  font-family: Poppins;
  color: rgb(0, 0, 0, 0.7);
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  margin-top: 8px;
}

.checkout_input {
  border-radius: 100px;
  font-size: 14px;
  line-height: 150%;
  color: rgb(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  margin-top: 4px;
  border: 1px solid #f0f0f0;
  padding: 12px 16px;
  width: 100%;
  outline: none;
}

.step_p {
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  line-height: 150%;
  margin-bottom: 8px;
}
