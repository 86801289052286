.text-center {
  text-align: center !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

.mt-4 {
  margin-top: 1.5rem !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.privacy-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  min-height: 100vh;
  color: #333;
}

.privacy-title {
  font-family: serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
  text-align: center;
}

.privacy-last-updated {
  font-family: sans-serif;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
}

.privacy-content {
  font-family: sans-serif;
  line-height: 1.6;
}

.privacy-content h2 {
  font-family: serif;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.privacy-content h3 {
  font-family: serif;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.privacy-content h4 {
  font-family: serif;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.privacy-content p {
  margin-bottom: 1rem;
}

.privacy-content ul {
  list-style-type: none;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.privacy-content ul li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;
}

.privacy-content ul li:before {
  content: "•";
  position: absolute;
  left: 0;
}
