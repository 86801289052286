.terms-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  min-height: 100vh;
  color: #333;
}

.terms-title {
  font-family: serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
}

.terms-last-updated {
  font-family: sans-serif;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 2rem;
}

.terms-content {
  font-family: sans-serif;
  line-height: 1.6;
}

.terms-content p {
  margin-bottom: 1rem;
}

.terms-content ul {
  list-style-type: none;
  padding-left: 1rem;
  margin-top: 1rem;
}

.terms-content ul li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;
}

.terms-content ul li:before {
  content: "•";
  position: absolute;
  left: 0;
} 